<template>
    <div>
        <ResetPassword v-if="show" @send="send"/>
        <ChangePassword v-else />
    </div>
</template>

<script>

import ResetPassword from "../../components/password/ResetPassword";
import ChangePassword from "../../components/password/ChangePassword";

export default {
    name: 'forgot-password',
    components: {
        ResetPassword,
        ChangePassword,
    },
    data: () => ({
        show: true,
    }),
    methods: {
        send() {
            this.show = false
        }
    }
}

</script>
